import ImgOne from '../assets/images/gallery/image-1.jpeg'
import ImgTwo from '../assets/images/gallery/image-2.jpeg'
import ImgThree from '../assets/images/gallery/image-3.jpeg'
import ImgFour from '../assets/images/gallery/image-4.jpeg'
import ImgFive from '../assets/images/gallery/image-5.jpeg'
import ImgSix from '../assets/images/gallery/image-6.jpeg'
import ImgSeven from '../assets/images/gallery/image-7.jpeg'
import ImgEight from '../assets/images/gallery/image-8.jpeg'
import ImgNine from '../assets/images/gallery/image-9.jpeg'
import ImgTen from '../assets/images/gallery/image-10.jpeg'
import ImgEleven from '../assets/images/gallery/image-11.jpeg'
import ImgTwelve from '../assets/images/gallery/image-12.jpeg'
import ImgThirteen from '../assets/images/gallery/image-13.jpeg'
import ImgFourteen from '../assets/images/gallery/image-14.jpeg'
import ImgFifteen from '../assets/images/gallery/image-15.jpeg'
import ImgSixteen from '../assets/images/gallery/image-16.jpeg'
import ImgSeventeen from '../assets/images/gallery/image-17.jpeg'
import ImgEighteen from '../assets/images/gallery/image-18.jpeg'
import ImgNineteen from '../assets/images/gallery/image-19.jpeg'
import ImgTwenty from '../assets/images/gallery/image-20.jpeg'
import ImgTwentyOne from '../assets/images/gallery/image-21.jpeg'
import ImgTwentyTwo from '../assets/images/gallery/image-22.jpeg'
import ImgTwentyThree from '../assets/images/gallery/image-23.jpeg'
import ImgTwentyFour from '../assets/images/gallery/image-24.jpeg'
import ImgTwentyFive from '../assets/images/gallery/image-25.jpeg'
import ImgTwentySix from '../assets/images/gallery/image-26.jpeg'
import ImgTwentySeven from '../assets/images/gallery/image-27.jpeg'
import ImgTwentyEight from '../assets/images/gallery/image-28.jpeg'
import ImgTwentyNine from '../assets/images/gallery/image-29.jpeg'
import ImgThirty from '../assets/images/gallery/image-30.jpeg'
import ImgThirtyOne from '../assets/images/gallery/image-31.jpeg'
import ImgThirtyTwo from '../assets/images/gallery/image-32.jpeg'
import ImgThirtyThree from '../assets/images/gallery/image-33.jpeg'
import ImgThirtyFour from '../assets/images/gallery/image-34.jpeg'
import ImgThirtyFive from '../assets/images/gallery/image-35.jpeg'
import ImgThirtySix from '../assets/images/gallery/image-36.jpeg'
import ImgThirtySeven from '../assets/images/gallery/image-37.jpeg'
import ImgThirtyEight from '../assets/images/gallery/image-38.jpeg'
import ImgThirtyNine from '../assets/images/gallery/image-39.jpeg'
import ImgFourty from '../assets/images/gallery/image-40.jpeg'
import ImgFourtyOne from '../assets/images/gallery/image-41.jpeg'
import ImgFourtyTwo from '../assets/images/gallery/image-42.jpeg'
import ImgFourtyThree from '../assets/images/gallery/image-43.jpeg'
import ImgFourtyFour from '../assets/images/gallery/image-44.jpeg'
import ImgFourtyFive from '../assets/images/gallery/image-45.jpeg'
import ImgFourtySix from '../assets/images/gallery/image-46.jpeg'
import ImgFourtySeven from '../assets/images/gallery/image-47.jpeg'
import ImgFourtyEight from '../assets/images/gallery/image-48.jpeg'
import ImgFourtyNine from '../assets/images/gallery/image-49.jpeg'
import ImgFifty from '../assets/images/gallery/image-50.jpeg'
import ImgFiftyOne from '../assets/images/gallery/image-51.jpeg'
import ImgFiftyTwo from '../assets/images/gallery/image-52.jpeg'
import ImgFiftyThree from '../assets/images/gallery/image-53.jpeg'
import ImgFiftyFour from '../assets/images/gallery/image-54.jpeg'
import ImgFiftyFive from '../assets/images/gallery/image-55.jpeg'
import ImgFiftySix from '../assets/images/gallery/image-56.jpeg'
import ImgFiftySeven from '../assets/images/gallery/image-57.jpeg'
import ImgFiftyEight from '../assets/images/gallery/image-58.jpeg'
import ImgFiftyNine from '../assets/images/gallery/image-59.jpeg'
import ImgSixty from '../assets/images/gallery/image-60.jpeg'
import ImgSixtyOne from '../assets/images/gallery/image-61.jpeg'
import ImgSixtyTwo from '../assets/images/gallery/image-62.jpeg'
import ImgSixtyThree from '../assets/images/gallery/image-63.jpeg'
import ImgSixtyFour from '../assets/images/gallery/image-64.jpeg'
import ImgSixtyFive from '../assets/images/gallery/image-65.jpeg'
import ImgSixtySix from '../assets/images/gallery/image-66.jpeg'
import ImgSixtySeven from '../assets/images/gallery/image-67.jpeg'
import ImgSixtyEight from '../assets/images/gallery/image-68.jpeg'
import ImgSixtyNine from '../assets/images/gallery/image-69.jpeg'
import ImgSeventy from '../assets/images/gallery/image-70.jpeg'
import ImgSeventyOne from '../assets/images/gallery/image-71.jpeg'
import ImgSeventyTwo from '../assets/images/gallery/image-72.jpeg'
import ImgSeventyThree from '../assets/images/gallery/image-73.jpeg'
import ImgSeventyFour from '../assets/images/gallery/image-74.jpeg'
import ImgSeventyFive from '../assets/images/gallery/image-75.jpeg'
import ImgSeventySix from '../assets/images/gallery/image-76.jpeg'
import ImgSeventySeven from '../assets/images/gallery/image-77.jpeg'
import ImgSeventyEight from '../assets/images/gallery/image-78.jpeg'
import ImgSeventyNine from '../assets/images/gallery/image-79.jpeg'
import ImgEighty from '../assets/images/gallery/image-80.jpeg'
import ImgEightyOne from '../assets/images/gallery/image-81.jpeg'
import ImgEightyTwo from '../assets/images/gallery/image-82.jpeg'
import ImgEightyThree from '../assets/images/gallery/image-83.jpeg'
import ImgEightyFour from '../assets/images/gallery/image-84.jpeg'
import ImgEightyFive from '../assets/images/gallery/image-85.jpeg'
import ImgEightySix from '../assets/images/gallery/image-86.jpeg'
import ImgEightySeven from '../assets/images/gallery/image-87.jpeg'
import ImgEightyEight from '../assets/images/gallery/image-88.jpeg'
import ImgEightyNine from '../assets/images/gallery/image-89.jpeg'
import ImgNinety from '../assets/images/gallery/image-90.jpeg'
import ImgNinetyOne from '../assets/images/gallery/image-91.jpeg'
import ImgNinetyTwo from '../assets/images/gallery/image-92.jpeg'
import ImgNinetyThree from '../assets/images/gallery/image-93.jpeg'
import ImgNinetyFour from '../assets/images/gallery/image-94.jpeg'
import ImgNinetyFive from '../assets/images/gallery/image-95.jpeg'
import ImgNinetySix from '../assets/images/gallery/image-96.jpeg'
import ImgNinetySeven from '../assets/images/gallery/image-97.jpeg'
import ImgNinetyEight from '../assets/images/gallery/image-98.jpeg'
import ImgNinetyNine from '../assets/images/gallery/image-99.jpeg'
import ImgHundred from '../assets/images/gallery/image-100.jpeg'
import ImgHundredOne from '../assets/images/gallery/image-101.jpeg'
import ImgHundredTwo from '../assets/images/gallery/image-102.jpeg'
import ImgHundredThree from '../assets/images/gallery/image-103.jpeg'
import ImgHundredFour from '../assets/images/gallery/image-104.jpeg'
import ImgHundredFive from '../assets/images/gallery/image-105.jpeg'
import ImgHundredSix from '../assets/images/gallery/image-106.jpeg'
import ImgHundredSeven from '../assets/images/gallery/image-107.jpeg'
import ImgHundredEight from '../assets/images/gallery/image-108.jpeg'
import ImgHundredNine from '../assets/images/gallery/image-109.jpeg'
import ImgHundredTen from '../assets/images/gallery/image-110.jpeg'
import ImgHundredEleven from '../assets/images/gallery/image-111.jpeg'
import ImgHundredTwelve from '../assets/images/gallery/image-112.jpeg'
import ImgHundredThirteen from '../assets/images/gallery/image-113.jpeg'
import ImgHundredFourteen from '../assets/images/gallery/image-114.jpeg'
import ImgHundredFifteen from '../assets/images/gallery/image-115.jpeg'
import ImgHundredSixteen from '../assets/images/gallery/image-116.jpeg'
import ImgHundredSeventeen from '../assets/images/gallery/image-117.jpeg'
import ImgHundredEighteen from '../assets/images/gallery/image-118.jpeg'
import ImgHundredNineteen from '../assets/images/gallery/image-119.jpeg'
import ImgHundredTwenty from '../assets/images/gallery/image-120.jpeg'
import ImgHundredTwentyOne from '../assets/images/gallery/image-121.jpeg'
import ImgHundredTwentyTwo from '../assets/images/gallery/image-122.jpeg'
import ImgHundredTwentyThree from '../assets/images/gallery/image-123.jpeg'
import ImgHundredTwentyFour from '../assets/images/gallery/image-124.jpeg'
import ImgHundredTwentyFive from '../assets/images/gallery/image-125.jpeg'
import ImgHundredTwentySix from '../assets/images/gallery/image-126.jpeg'
import ImgHundredTwentySeven from '../assets/images/gallery/image-127.jpeg'
import ImgHundredTwentyEight from '../assets/images/gallery/image-128.jpeg'
import ImgHundredTwentyNine from '../assets/images/gallery/image-129.jpeg'
import ImgHundredThirty from '../assets/images/gallery/image-130.jpeg'

const GalleryData = [
  { id: 1, src: ImgOne, alt: 'Georgia' },
  { id: 2, src: ImgTwo, alt: 'Georgia' },
  { id: 3, src: ImgThree, alt: 'Georgia' },
  { id: 4, src: ImgFour, alt: 'Georgia' },
  { id: 5, src: ImgFive, alt: 'Georgia' },
  { id: 6, src: ImgSix, alt: 'Georgia' },
  { id: 7, src: ImgSeven, alt: 'Georgia' },
  { id: 8, src: ImgEight, alt: 'Georgia' },
  { id: 9, src: ImgNine, alt: 'Georgia' },
  { id: 10, src: ImgTen, alt: 'Georgia' },
  { id: 11, src: ImgEleven, alt: 'Georgia' },
  { id: 12, src: ImgTwelve, alt: 'Georgia' },
  { id: 13, src: ImgThirteen, alt: 'Georgia' },
  { id: 14, src: ImgFourteen, alt: 'Georgia' },
  { id: 15, src: ImgFifteen, alt: 'Georgia' },
  { id: 16, src: ImgSixteen, alt: 'Georgia' },
  { id: 17, src: ImgSeventeen, alt: 'Georgia' },
  { id: 18, src: ImgEighteen, alt: 'Georgia' },
  { id: 19, src: ImgNineteen, alt: 'Georgia' },
  { id: 20, src: ImgTwenty, alt: 'Georgia' },
  { id: 21, src: ImgTwentyOne, alt: 'Georgia' },
  { id: 22, src: ImgTwentyTwo, alt: 'Georgia' },
  {
    id: 23,
    src: ImgTwentyThree,
    alt: 'Georgia',

  },
  {
    id: 24,
    src: ImgTwentyFour,
    alt: 'Georgia',

  },
  {
    id: 25,
    src: ImgTwentyFive,
    alt: 'Georgia',

  },
  { id: 26, src: ImgTwentySix, alt: 'Georgia' },
  {
    id: 27,
    src: ImgTwentySeven,
    alt: 'Georgia',

  },
  {
    id: 28,
    src: ImgTwentyEight,
    alt: 'Georgia',

  },
  {
    id: 29,
    src: ImgTwentyNine,
    alt: 'Georgia',

  },
  { id: 30, src: ImgThirty, alt: 'Georgia', },
  { id: 31, src: ImgThirtyOne, alt: 'Georgia', },
  { id: 32, src: ImgThirtyTwo, alt: 'Georgia', },
  {
    id: 33,
    src: ImgThirtyThree,
    alt: 'Georgia',

  },
  {
    id: 34,
    src: ImgThirtyFour,
    alt: 'Georgia',

  },
  {
    id: 35,
    src: ImgThirtyFive,
    alt: 'Georgia',

  },
  { id: 36, src: ImgThirtySix, alt: 'Georgia', },
  {
    id: 37,
    src: ImgThirtySeven,
    alt: 'Georgia',

  },
  {
    id: 38,
    src: ImgThirtyEight,
    alt: 'Georgia',

  },
  {
    id: 39,
    src: ImgThirtyNine,
    alt: 'Georgia',

  },
  { id: 40, src: ImgFourty, alt: 'Georgia', },
  { id: 41, src: ImgFourtyOne, alt: 'Georgia', },
  { id: 42, src: ImgFourtyTwo, alt: 'Georgia', },
  {
    id: 43,
    src: ImgFourtyThree,
    alt: 'Georgia',

  },
  {
    id: 44,
    src: ImgFourtyFour,
    alt: 'Georgia',

  },
  {
    id: 45,
    src: ImgFourtyFive,
    alt: 'Georgia',

  },
  { id: 46, src: ImgFourtySix, alt: 'Georgia', },
  {
    id: 47,
    src: ImgFourtySeven,
    alt: 'Georgia',

  },
  {
    id: 48,
    src: ImgFourtyEight,
    alt: 'Georgia',

  },
  {
    id: 49,
    src: ImgFourtyNine,
    alt: 'Georgia',

  },
  { id: 50, src: ImgFifty, alt: 'Georgia', },
  { id: 51, src: ImgFiftyOne, alt: 'Georgia', },
  { id: 52, src: ImgFiftyTwo, alt: 'Georgia', },
  {
    id: 53,
    src: ImgFiftyThree,
    alt: 'Georgia',

  },
  { id: 54, src: ImgFiftyFour, alt: 'Georgia', },
  { id: 55, src: ImgFiftyFive, alt: 'Georgia', },
  { id: 56, src: ImgFiftySix, alt: 'Georgia', },
  {
    id: 57,
    src: ImgFiftySeven,
    alt: 'Georgia',

  },
  {
    id: 58,
    src: ImgFiftyEight,
    alt: 'Georgia',

  },
  { id: 59, src: ImgFiftyNine, alt: 'Georgia', },
  { id: 60, src: ImgSixty, alt: 'Georgia', },
  { id: 61, src: ImgSixtyOne, alt: 'Georgia', },
  { id: 62, src: ImgSixtyTwo, alt: 'Georgia', },
  {
    id: 63,
    src: ImgSixtyThree,
    alt: 'Georgia',

  },
  { id: 64, src: ImgSixtyFour, alt: 'Georgia', },
  { id: 65, src: ImgSixtyFive, alt: 'Georgia', },
  { id: 66, src: ImgSixtySix, alt: 'Georgia', },
  {
    id: 67,
    src: ImgSixtySeven,
    alt: 'Georgia',

  },
  {
    id: 68,
    src: ImgSixtyEight,
    alt: 'Georgia',

  },
  { id: 69, src: ImgSixtyNine, alt: 'Georgia', },
  { id: 70, src: ImgSeventy, alt: 'Georgia',  },
  {
    id: 71,
    src: ImgSeventyOne,
    alt: 'Georgia',

  },
  {
    id: 72,
    src: ImgSeventyTwo,
    alt: 'Georgia',

  },
  {
    id: 73,
    src: ImgSeventyThree,
    alt: 'Georgia',
  },
  {
    id: 74,
    src: ImgSeventyFour,
    alt: 'Georgia',

  },
  {
    id: 75,
    src: ImgSeventyFive,
    alt: 'Georgia',

  },
  {
    id: 76,
    src: ImgSeventySix,
    alt: 'Georgia',

  },
  {
    id: 77,
    src: ImgSeventySeven,
    alt: 'Georgia',

  },
  {
    id: 78,
    src: ImgSeventyEight,
    alt: 'Georgia',

  },
  {
    id: 79,
    src: ImgSeventyNine,
    alt: 'Georgia',

  },
  { id: 80, src: ImgEighty, alt: 'Georgia', },
  { id: 81, src: ImgEightyOne, alt: 'Georgia', },
  { id: 82, src: ImgEightyTwo, alt: 'Georgia', },
  {
    id: 83,
    src: ImgEightyThree,
    alt: 'Georgia',

  },
  {
    id: 84,
    src: ImgEightyFour,
    alt: 'Georgia',
  },
  {
    id: 85,
    src: ImgEightyFive,
    alt: 'Georgia',

  },
  { id: 86, src: ImgEightySix, alt: 'Georgia' },
  {
    id: 87,
    src: ImgEightySeven,
    alt: 'Georgia',
  },
  {
    id: 88,
    src: ImgEightyEight,
    alt: 'Georgia',
  },
  {
    id: 89,
    src: ImgEightyNine,
    alt: 'Georgia',

  },
  { id: 90, src: ImgNinety, alt: 'Georgia', },
  { id: 91, src: ImgNinetyOne, alt: 'Georgia', },
  { id: 92, src: ImgNinetyTwo, alt: 'Georgia', },
  {
    id: 93,
    src: ImgNinetyThree,
    alt: 'Georgia',

  },
  {
    id: 94,
    src: ImgNinetyFour,
    alt: 'Georgia',

  },
  {
    id: 95,
    src: ImgNinetyFive,
    alt: 'Georgia',

  },
  { id: 96, src: ImgNinetySix, alt: 'Georgia', },
  {
    id: 97,
    src: ImgNinetySeven,
    alt: 'Georgia',

  },
  {
    id: 98,
    src: ImgNinetyEight,
    alt: 'Georgia',

  },
  {
    id: 99,
    src: ImgNinetyNine,
    alt: 'Georgia',

  },
  { id: 100, src: ImgHundred, alt: 'Georgia', },
  {
    id: 101,
    src: ImgHundredOne,
    alt: 'Georgia',

  },
  {
    id: 102,
    src: ImgHundredTwo,
    alt: 'Georgia',

  },
  {
    id: 103,
    src: ImgHundredThree,
    alt: 'Georgia',

  },
  {
    id: 104,
    src: ImgHundredFour,
    alt: 'Georgia',

  },
  {
    id: 105,
    src: ImgHundredFive,
    alt: 'Georgia',

  },
  {
    id: 106,
    src: ImgHundredSix,
    alt: 'Georgia',

  },
  {
    id: 107,
    src: ImgHundredSeven,
    alt: 'Georgia',

  },
  {
    id: 108,
    src: ImgHundredEight,
    alt: 'Georgia'
  },
  {
    id: 109,
    src: ImgHundredNine,
    alt: 'Georgia',
  },
  {
    id: 110,
    src: ImgHundredTen,
    alt: 'Georgia'
  },
  {
    id: 111,
    src: ImgHundredEleven,
    alt: 'Georgia'
  },
  {
    id: 112,
    src: ImgHundredTwelve,
    alt: 'Georgia'
  },
  {
    id: 113,
    src: ImgHundredThirteen,
    alt: 'Georgia',
  },
  {
    id: 114,
    src: ImgHundredFourteen,
    alt: 'Georgia'
  },
  {
    id: 115,
    src: ImgHundredFifteen,
    alt: 'Georgia',
  },
  {
    id: 116,
    src: ImgHundredSixteen,
    alt: 'Georgia'
  },
  {
    id: 117,
    src: ImgHundredSeventeen,
    alt: 'Georgia'
  },
  {
    id: 118,
    src: ImgHundredEighteen,
    alt: 'Georgia',

  },
  {
    id: 119,
    src: ImgHundredNineteen,
    alt: 'Georgia',

  },
  {
    id: 120,
    src: ImgHundredTwenty,
    alt: 'Georgia',

  },
  {
    id: 121,
    src: ImgHundredTwentyOne,
    alt: 'Georgia',

  },
  {
    id: 122,
    src: ImgHundredTwentyTwo,
    alt: 'Georgia',

  },
  {
    id: 123,
    src: ImgHundredTwentyThree,
    alt: 'Georgia',

  },
  {
    id: 124,
    src: ImgHundredTwentyFour,
    alt: 'Georgia',

  },
  {
    id: 125,
    src: ImgHundredTwentyFive,
    alt: 'Georgia',

  },
  {
    id: 126,
    src: ImgHundredTwentySix,
    alt: 'Georgia',

  },
  {
    id: 127,
    src: ImgHundredTwentySeven,
    alt: 'Georgia',

  },
  {
    id: 128,
    src: ImgHundredTwentyEight,
    alt: 'Georgia',

  },
  {
    id: 129,
    src: ImgHundredTwentyNine,
    alt: 'Georgia',

  },
  {
    id: 130,
    src: ImgHundredThirty,
    alt: 'Georgia',

  },
]



export default GalleryData;
